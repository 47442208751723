<template>
  <div>
    <a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
      <template slot="footer" >
        <a-button  key="back"  @click="handleCancel">
          取消
        </a-button>
      </template>
      <a-spin :spinning="loading">
        <template>
          <a-list :data-source="shopData">
            <a-list-item slot="renderItem" slot-scope="item, index">
              <a-list-item-meta :description="item.shop.address">
                <a slot="title">{{ item.shop.name}}</a>
                <a-avatar
                    slot="avatar"
                    :src="item.shop.site_logo"
                />
              </a-list-item-meta>
              <div class="ft14 ftw500 cl-theme ml8" style="cursor:pointer;" @click="changeSite(item.shop.shop_id)" v-if="client_site_name != item.shop.name">切换至该站点</div>
            </a-list-item>
          </a-list>


        </template>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>

export default {
  props: {
    visible:{
      type:Boolean,
      default:false,
    },
    shopData:{
      type:Array,
      default:function(){
        return new Array();
      },
    }
  },
  data() {
    return {
      loading:false,
      client_site_name:localStorage.getItem('client-site-info'),
    }
  },
  computed:{
    getTitle(){
      return "当前站点："+this.client_site_name;
    },
  },
  methods: {

    /**
     * 取消弹窗
     */
    handleCancel() {
      this.$emit("cancel");
    },
    changeSite(shop_id){
      this.loading=true;
      this.$http.api('admin/changeShop',{
        s:shop_id,
      }).then(res=>{
        this.$message.success('已切换至 '+res.site_info_name);
        this.$emit("cancel");
        this.loading=false;
        localStorage.setItem('housekeeping-token',res.auth_token);
        localStorage.setItem('client-site-info',res.site_info_name);
        this.$router.push('/index');
      }).catch(res=>{
        this.loading=false;
      })

    }
  }
}
</script>

<style>
</style>
